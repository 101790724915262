@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.formContainer {
  width: 100%;
  background: var(--alpha-listMenu);
  border-radius: 20px;
  //padding: rem(20);

  .formTitle {
    font-weight: 600;
    font-size: 16px;
    //text-align: center;
    width: 100%;

    > div {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .formSubTitle {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }

  .formDesc {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--alpha-formDesc) !important;
    width: 100%;
    text-align: left;
  }

  .btnSubmit {
    background: var(--alpha-btn-bg);
    color: var(--alpha-btn-text);
    height: 40px;
    border-radius: 100px;
    font-size: rem(14);
    font-weight: 500;

    :global {
      .chakra-button__spinner {
        color: #000000 !important;
      }
    }

    > div {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .formWrapper {
    width: rem(369);
   
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &_border{
      background: var(--alpha-walletBlockBg);
      border: 1px solid var(--alpha-formBoxBg)
    }
  }

  :global {
    .chakra-text {
      color: var(--alpha-text)
    }
  }
}

.formBox {
  background: var(--alpha-formBoxBg) !important;
}

.inputAmountWrap {
  margin-bottom: 8px;

  :global .chakra-input__left-element {
    margin: 0;
    width: unset;
    border-right: 1px solid #5B5B5B;
  }

  :global .chakra-input__right-element {
    padding-right: 16px;
    margin: 0;
  }

  :global .chakra-form__error-message {
    font-size: 12px;
  }

  :global .chakra-input__group {
    background: transparent;
  }
}

.inputAmount {
  font-size: 14px !important;
  height: 48px !important;
  font-weight: 500 !important;
  //text-align: center !important;

  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px !important;
  }
}

.btnContinue {
  width: 100%;
}

.btnBuyKey {
  height: 34px !important;
  padding: 0 !important;
  //min-width: 150px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.wrapTwitter {
  background: var(--alpha-navBg) !important;
}
