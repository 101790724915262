@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.wrapper {
  position: relative;
  //background: var(--alpha-bg);
  //height: 100svh;
  //padding: 0 20px;
  //text-align: center;
  //display: grid;
  //place-items: center;

  //* {
  //  color: var(--alpha-text);
  //}


  .text {
    font-size: rem(12px);
    line-height: rem(16.8px);
    text-align: center;
    color: #cecece;
  }

  .troubleText {
    font-size: 12px;
    font-weight: 400;
    color: #F8F0AC;
    text-align: center;
    margin: 8px 0;
  }
}
