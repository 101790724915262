@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.modalContent {
  //width: 100vw !important;
  //background: rgba(#000000, .9) !important;
  //height: 100svh !important;
  overflow: hidden;
  padding-top: calc(env(safe-area-inset-top) + 20px) !important;

  :global {
    .chakra-modal__close-btn {
      top: 40px !important;
      z-index: 1;
    }

    .chakra-modal__body {
      overflow: hidden;
    }
  }
}
