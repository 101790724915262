@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.modal {
  :global(.modal-dialog) {
    @include mobile {
      margin: 20px;
      min-height: calc(100% - 20px * 2);
    }
  }
}

.backdrop {
  opacity: 0.5 !important;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--alpha-bg);
}

.modalBody {
  padding: 16px;
  border-radius: 16px;
  background: var(--alpha-bg);
}

.thumbnailWrapper {
  margin-bottom: 12px;
}

.logo {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-family: var(--font-heading);
  color: var(--alpha-text);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  margin-top: 16px;
}

.subTitle {
  font-family: var(--font-body);
  color: var(--alpha-text);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-bottom: 32px;

  &:nth-of-type(2) {
    margin-bottom: 12px;

  }
}

.subTitle {
  max-width: rem(420px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
}

.subTextBottom {
  margin-top: 197px;
  font-family: var(--font-body);
  color: var(--alpha-text);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.stepList {
  margin-bottom: 32px;
}

.stepItem {
  font-family: var(--font-body);
  color: var(--alpha-text);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--alpha-stepItem-border);
  background: var(--alpha-stepItem-bg);
  box-shadow: 0px 0px 20px -6px rgba(116, 200, 246, 0.16);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.shareIosImg {
  height: 36px;
}

.addHomeIosImg {
  height: 36px;
}
