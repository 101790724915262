@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.logInWebOnAndroid {
  margin: 20px 0 0;

  .lineWrapper {
    margin-bottom: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &_text {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .line {
    width: 48px;
    height: 1px;
    opacity: 0.5;
    background: #f8f0ac;
  }

  .linkWrapper {
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    &_text {
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
    }
  }
  .link {
    width: 279px;
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    border: 1px solid rgba(248, 240, 172, 0.2);
    background: rgba(248, 240, 172, 0.1);

    &_text {
      color: #f8f0ac;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }
}
