@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.createOrImportWallet {
  //height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  //background-color: var(--alpha-bg);
  margin-top: 20px;

  .mb_17 {
    margin-bottom: 17px;
  }

  .title {
    margin-bottom: 24px;
    color: var(--alpha-text);
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .caption {
    margin-bottom: 24px;
    color: var(--alpha-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .content {
    color: var(--alpha-text);
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .note {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // li {
    //   word-break: break-word;

    // &:before {
    //   content: '👉';
    //   padding-right: 8px;
    // }

    // &:not(:last-child) {
    //   margin-bottom: 17px;
    // }
    // }
  }

  .actionBlock {
    width: 100%;
    margin: 36px 0 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    button {
      border: none;
    }

    svg {
      path {
        fill: var(--alpha-checkBoxIcon);
      }
    }
  }

  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    background: var(--alpha-btn-bg);
    gap: 4px;

    color: var(--alpha-btn-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .lowPriorityBtn {
    background: rgba(248, 240, 172, 0.4);
    color: #f8f0ac;
    svg {
      path {
        fill: var(--alpha-btn-bg);
      }
    }
  }

  .warningText {
    margin-top: 10px;
    color: var(--alpha-text);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .loadingWrapper {
    padding-top: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
